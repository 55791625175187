@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html {
    scroll-behavior: smooth;
  }

h1, h2, h3, h4, h5, p {
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

h1 {
    margin-top: 0 !important;
}
span {
    font-family: 'Poppins', sans-serif !important;
    word-wrap: break-word !important;
}

.section--title {
    font-weight: 600 !important;
}

// sections padding

// Mobile
@media only screen and (max-width: 480px) {  
    .services, .areas, .about, .reviews, .contact, .footer {
        padding: 90px 4rem;
    }
  }
  // Tablet
  @media only screen and (min-width: 481px) and (max-width: 800px) {  
    .services, .areas, .about, .reviews, .contact, .footer {
        padding: 4rem 4rem;
    }
    .css-185gdzj-MuiCardHeader-root {
        padding: 0 !important;
    }
  }
  @media only screen and (min-width: 801px) and (max-width: 1227px) {  
    .services, .areas, .about, .reviews, .contact, .footer {
        padding: 6rem 8rem;
    }
  }
  // Desktop
  @media only screen and (min-width: 1228px) and (max-width: 1500px) {  
    .services, .areas, .about, .reviews, .contact, .footer {
        padding: 6rem 10rem;
    }
  }
  @media only screen and (min-width: 1501px) {  
    .services, .areas, .about, .reviews, .contact, .footer {
        padding: 6rem 24rem;
    }
  }