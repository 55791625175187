.about {
    background-color: #f4f6f7;
    
}


.about-paper-wrapper {
    display: flex;
    text-align: center;
    justify-content: center;
}

.about-paper-item {
    flex-direction: column;
    width: 45%;
}

.about-image {
    height: 15vh;
}


// Mobile
@media screen and (max-width: 480px) {  
    .about-item {
        width: 100%;
    }
  }
  // Tablet
  @media screen and (min-width: 481px) and (max-width: 768px) {  
    .about-item {
        width: 60%;
    }
  }
  // Desktop
  @media screen and (min-width: 769px) {  
    .about-item {
        width: 60%;
    }
  }