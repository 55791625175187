.contact {
  background-color: #f4f6f7;
  // height: 100vh;
}
.contact-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.form-input-select {
  border-color: #a4a4a4;
  color: #6c6c6c;
  width: 100%;
  height: 44px;
  border: 2px solid;
  padding-left: 44px;
  padding-right: 12px;
  position: relative;
  font-size: 16px;
  border-radius: 5px;
}
.contact-form-wrapper {
  padding-top: 2rem !important;
}

.contact-card-main {
  display: grid;
  gap: 10px;
  padding: 0 4rem;
}
a {
  color: #121212;
}

.contact-card {
  margin: 1rem;
  width: 30%;
}
.contact-item--last {
  text-decoration: none !important;
}
.contact-item:focus,
.contact-item:hover,
.contact-item:visited,
.contact-item:link,
.contact-item:active {
  text-decoration: none;
}
.contact-item > h4:last-of-type {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 200;
  text-decoration: none;
}

.contact-item > h4:first-of-type {
  text-decoration: underline;
}

.contact-item {
  padding: 24px !important;
  h4 {
    margin-bottom: 0;
  }

  @media screen and (max-width: 400px) {
    flex: 1 0 100%; /* Each div on a separate row */
  }
  
  @media screen and (max-width: 1008px) {
    flex: 1 0 50%; /* 2 divs on one row */
  }

  @media screen and (min-width: 1009px) {
    flex: 1 0 33.33%; /* Default - all 3 divs on one row */
    }
}

// Mobile
@media screen and (max-width: 480px) {
  .contact-form-wrapper {
    padding: 0;
  }
}
// Tablet
@media screen and (min-width: 481px) and (max-width: 600px) {
  .contact-form-wrapper {
    padding: 0 4rem;
  }
}
@media screen and (min-width: 601px) and (max-width: 870px) {
  .contact-form-wrapper {
    padding: 0 4rem;
  }
}
// Desktop
@media screen and (min-width: 869px) {
  .contact-form-wrapper {
    padding: 0 8rem;
  }
}

//  FORM

.contact iframe {
  width: 50vh;
  height: 50vh;
  margin: auto;
  padding: auto;
  display: block;
}

p.form-message.success,
p.form-message.error {
  font-size: 16px;
  color: #333;
  background: #ddd;
  padding: 10px 15px;
  margin-top: 15px;
  margin-left: 15px;
}
p.form-message.success.form-message.error,
p.form-message.error.form-message.error {
  color: #f00;
}

.form-input .input-items input,
.form-input .input-items textarea {
  width: 100%;
  height: 44px;
  border: 2px solid;
  padding-left: 44px;
  padding-right: 12px;
  position: relative;
  font-size: 16px;
}
.form-input .input-items textarea {
  padding-top: 8px;
  height: 130px;
  resize: none;
}
.input-items {
  position: relative;
}
.form-input .input-items.default input,
.form-input .input-items.default textarea {
  border-color: #a4a4a4;
  color: #6c6c6c;
}
.form-input .input-items.default input:focus,
.form-input .input-items.default textarea:focus {
  border-color: #d2bb0d;
}

.form-input .required {
  color: red;
}
.form-input label {
  font-size: 12px;
  line-height: 18px;
  color: #6c6c6c;
  margin-bottom: 8px;
  display: inline-block;
  font-family: "Poppins", sans-serif !important;
}

.form-input input {
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 44px;
  border-color: #a4a4a4;
  color: #6c6c6c;
}
.form-input.default input {
  border-color: #a4a4a4;
  color: #6c6c6c;
}

.form-input .input-items input {
  width: 100%;
  height: 44px;
  border: 2px solid;
  padding-left: 44px;
  padding-right: 12px;
  position: relative;
  font-size: 16px;
}

.form-input .input-items.default input::placeholder,
.form-input .input-items.default textarea::placeholder {
  color: #6c6c6c;
  opacity: 1;
}
.form-input .input-items.default input::-moz-placeholder,
.form-input .input-items.default textarea::-moz-placeholder {
  color: #6c6c6c;
  opacity: 1;
}
.form-input .input-items.default input::-moz-placeholder,
.form-input .input-items.default textarea::-moz-placeholder {
  color: #6c6c6c;
  opacity: 1;
}
.form-input .input-items.default input::-webkit-input-placeholder,
.form-input .input-items.default textarea::-webkit-input-placeholder {
  color: #6c6c6c;
  opacity: 1;
}
.form-input .input-items.active input,
.form-input .input-items.active textarea {
  border-color: #d2bb0d;
  color: #121212;
}
.form-input .input-items.active input::placeholder,
.form-input .input-items.active textarea::placeholder {
  color: #121212;
  opacity: 1;
}
.form-input .input-items.active input::-moz-placeholder,
.form-input .input-items.active textarea::-moz-placeholder {
  color: #121212;
  opacity: 1;
}
.form-input .input-items.active input::-moz-placeholder,
.form-input .input-items.active textarea::-moz-placeholder {
  color: #121212;
  opacity: 1;
}
.form-input .input-items.active input::-webkit-input-placeholder,
.form-input .input-items.active textarea::-webkit-input-placeholder {
  color: #121212;
  opacity: 1;
}
.form-input .input-items.active i {
  color: #d2bb0d;
}
.form-input .input-items.error input,
.form-input .input-items.error textarea {
  border-color: #fc3832;
  color: #fc3832;
}
.form-input .input-items.error input::placeholder,
.form-input .input-items.error textarea::placeholder {
  color: #fc3832;
  opacity: 1;
}
.form-input .input-items.error input::-moz-placeholder,
.form-input .input-items.error textarea::-moz-placeholder {
  color: #fc3832;
  opacity: 1;
}
.form-input .input-items.error input::-moz-placeholder,
.form-input .input-items.error textarea::-moz-placeholder {
  color: #fc3832;
  opacity: 1;
}
.form-input .input-items.error input::-webkit-input-placeholder,
.form-input .input-items.error textarea::-webkit-input-placeholder {
  color: #fc3832;
  opacity: 1;
}
.form-input .input-items.error i {
  color: #fc3832;
}
.form-input .input-items.success input,
.form-input .input-items.success textarea {
  border-color: #c3d936;
  color: #c3d936;
}
.form-input .input-items.success input::placeholder,
.form-input .input-items.success textarea::placeholder {
  color: #c3d936;
  opacity: 1;
}
.form-input .input-items.success input::-moz-placeholder,
.form-input .input-items.success textarea::-moz-placeholder {
  color: #c3d936;
  opacity: 1;
}
.form-input .input-items.success input::-moz-placeholder,
.form-input .input-items.success textarea::-moz-placeholder {
  color: #c3d936;
  opacity: 1;
}
.form-input .input-items.success input::-webkit-input-placeholder,
.form-input .input-items.success textarea::-webkit-input-placeholder {
  color: #c3d936;
  opacity: 1;
}
.form-input .input-items.success i {
  color: #c3d936;
}
.form-input .input-items.disabled input,
.form-input .input-items.disabled textarea {
  border-color: #a4a4a4;
  color: #6c6c6c;
  background: none;
}
.form-input .input-items.disabled input::placeholder,
.form-input .input-items.disabled textarea::placeholder {
  color: #6c6c6c;
  opacity: 1;
}
.form-input .input-items.disabled input::-moz-placeholder,
.form-input .input-items.disabled textarea::-moz-placeholder {
  color: #6c6c6c;
  opacity: 1;
}
.form-input .input-items.disabled input::-moz-placeholder,
.form-input .input-items.disabled textarea::-moz-placeholder {
  color: #6c6c6c;
  opacity: 1;
}
.form-input .input-items.disabled input::-webkit-input-placeholder,
.form-input .input-items.disabled textarea::-webkit-input-placeholder {
  color: #6c6c6c;
  opacity: 1;
}
.form-input .input-items.disabled i {
  color: #6c6c6c;
}

.form-input .input-items textarea {
  font-family: "Poppins", sans-serif !important;

  border-radius: 5px;
  padding-left: 12px;
  padding-right: 44px;
}
.form-input .input-items.default i {
  color: #6c6c6c;
}
.form-input .input-items i {
  position: absolute;
  top: 11px;
  left: 13px;
  font-size: 20px;
  z-index: 9;
}

.input-items i {
  left: auto;
  right: 13px;
}
