:root {
  --swiper-theme-color: #d2bb0d !important;
}

.swiper-slide svg {
  // background-color: $color-primary;
  height: 100%;
  background-color: #fff;
  animation: anibg01 10s infinite linear;
  // background-image: linear-gradient(0deg, #0d4ba0, #00adee, #00aba5, #37b34a, #8dc63f, #ccdb29, #ffdd15, #fff100, #f6921e, #f05a28, #ec1c24, #ff008b, #90278e, #652d90, #0d4ba0, #00adee);
  background-size: 100% 100%;
  // width: 20vmin;
  // height: 10vmin;
  display: inline-block;
  // clip-path: polygon(50% 0, 100% 0, 50% 100%, 0 100%);
}
