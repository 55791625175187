.footer {
  // background-color: #121212;
  // color: white;
//   
}
.footer a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.footer-area {
  background-color: #f4f6f7;
  padding-top: 90px;
  padding-bottom: 120px;
}
.social {
    text-align: center;
    padding: 0 !important;
}
.footer-area .social li {
  display: inline-block;
  margin: 0 5px;
}
.footer-area .social li a {
  font-size: 24px;
  color: #6c6c6c;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-area .social li a:hover {
  color: #d2bb0d;
}
.footer-area .footer-support {
  padding-top: 21px;
}
.footer-area .footer-support span {
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  font-weight: 600;
  margin-top: 9px;
  display: block;
}
@media (max-width: 767px) {
  .footer-area .footer-support span {
    display: block;
  }
}
.footer-area .footer-app-store {
  padding-top: 27px;
}
.footer-area .footer-app-store ul li {
  display: inline-block;
  margin-right: 8px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-area .footer-app-store ul li {
    margin-right: 6px;
  }
}
.footer-area .footer-app-store ul li:last-child {
  margin-right: 0;
}
@media (max-width: 767px) {
  .footer-area .footer-app-store ul li {
    width: 120px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-area .footer-app-store ul li {
    width: auto;
  }
}
.footer-area .copyright .text {
  color: #121212;
}
.footer-area.footer-dark {
  background-color: #121212;
}
.footer-area.footer-dark .social li a {
  color: #fff;
}
.footer-area.footer-dark .footer-support {
  padding-top: 21px;
}
.footer-area.footer-dark .footer-support span {
  color: #fff;
}
.footer-area.footer-dark .footer-app-store {
  padding-top: 27px;
}
.footer-area.footer-dark .footer-app-store ul li {
  color: #fff;
}
.footer-area.footer-dark .copyright .text {
  color: #fff;
}
