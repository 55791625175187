.reviews--icons {
    display: flex;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    height: 4vh;
}

.bottom-nav {
    height: 15vh !important;
}
.css-gy4i07-MuiPaper-root {
    z-index: 99999;
}