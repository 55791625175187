.services {
  background-color: #f4f6f7;
  display: flex;
  flex-direction: column;
}

.services-card {
  margin: 1rem 0.5rem;
  flex: 1 1 0;
}
.brand-slider,
.box,
.slide {
  // height: 20vh !important;
}

// Mobile
@media screen and (max-width: 480px) {
  .carousel {
    display: none;
  }
  .carousel-slider {
    width: 100% !important;
  }
}
// Tablet
@media screen and (max-width: 1094px) {
  .brand-slider {
    //margin-bottom: 5rem;
    width: 100%;
    height: max-content;
  }
  .carousel-root {
    height: 100%;
  }
  .carousel-slider {
    width: 50%;
    display: grid;
    height: 150px;
  }
  .slider-wrapper {
    height: 150px;
    align-items: center;
  }
  .slide {
    height: 15vh !important;
  }
  .slide > img {
    object-fit: scale-down !important;
    // align-items: center;
  }
}
// Desktop
// @media screen and (min-width: 769px) and (max-width: 1094px) {
//   .brand-slider {
//     //margin-bottom: 5rem;
//     width: 100%;
//   }
//   .carousel-root {
//     height: 30vh;
//   }
//   .slide > img {
//     object-fit: scale-down !important;
//     // align-items: center;
//   }
// }
// XL Desktop
@media screen and (min-width: 1094px) {
  .brand-slider {
    // margin-bottom: 5rem;
    // width: 100%;
  }
  .carousel-slider {
    width: 80%;
    height: 20vh;
  }
  .thumbs-wrapper {
    height: 10vh;
  }
  .carousel-root {
    align-content: flex-start;
  }
  .slide > img {
    // min-width: 50%;
    // object-fit: fill;
    height: 20vh;
    object-fit: contain !important;
  }
}

.slider-wrapper {
  display: flex;
}

.control-dots {
  display: none !important;
}
.carousel-root {
  align-items: center !important;
  display: flex;
  flex-direction: column;
}

.services-card-content > div > span:first-of-type {
  font-size: 1.2rem !important;
}

// Mobile
@media screen and (max-width: 480px) {
  .services-card-wrapper {
    align-self: center;
    display: flex;
    flex-direction: column !important;
  }
  .slide {
    height: 10vh !important;
  }
}
// Tablet
@media screen and (min-width: 481px) and (max-width: 768px) {
  .services-card-wrapper {
    align-self: center;
    display: flex;
  }
  .services-card {
    flex-direction: row !important;
  }
  .services-card-content {
    align-items: baseline !important;
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }
  .css-1ssile9-MuiCardHeader-avatar {
    justify-content: center;
    width: 100%;
  }
}
// Desktop
@media screen and (min-width: 769px) {
  .services-card-wrapper {
    align-self: center;
    display: flex;
  }
  .services-card {
    flex-direction: row !important;
  }
  .services-card-content {
    align-items: baseline !important;
  }
}
