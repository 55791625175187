.areas {
    
    // height: 100vh;
}
.areas > div p {
    font-family: 'Poppins', sans-serif !important;
    text-align: left !important;
}
.areas-expanded-content p {
    text-align: left !important;
}
.area-google-map {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    padding: 2rem;
}