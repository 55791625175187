.header {
    height: 100vh
}

.mui--nav--bar {
    color: white;
    background-color: rgba($color: $color-primary, $alpha: 0.01);
    box-shadow: none !important;
    // mix-blend-mode: overlay;
}

.nav--drawer--content {
    display: flex;
    width: 100%;
}

.nav--drawer--content > svg {
    align-self: center;
}

.nav--drawer--Logo {
// width: 100%;
}

.nav--drawer--text {
    margin: 0;
    // color: $color-dark-grey;
    align-self: center;
}

.nav--drawer--MenuIcon {

}
.nav--button--text {
    color: $color-dark-grey !important;
}

.top-nav-left-content {
    display: flex;
}

.top-nav-header-text {
    margin: 0;
    text-align: left;
    align-self: center;
}

@media only screen and (min-width: 600px) {  
    .nav--button--text {
        color: white !important;
    }
  }