/* #app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  } */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: white;

  /* Center slide text vertically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .slide-one {
  color: white;
  background-image: url("../../assets/images/repairs-for-range-cookers-sheffield-mansfield-nottingham-header-image-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative; 
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide-one::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(0,0,0,0.5);
} */

.slide-one, .slide-two {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide-one::before {
  content: "";
  background-image: url("../../assets/images/repairs-for-range-cookers-sheffield-mansfield-nottingham-header-image-1.jpg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.75;
  background-position: center center;
}
.slide-two::before {
  content: "";
  background-image: url("../../assets/images/repairs-for-range-cookers-sheffield-mansfield-nottingham-header-image-2.jpg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.9;
  background-position: center center;
}
.title,
.text {
  position: relative;
  color: #ffffff;
  text-align: center;
  margin: 0;
}

.header--label {
  width: max-content;
  border-radius: 25px;
  background-color: white;
  position: relative;
  padding: 0.2rem 0.6rem;
  margin-bottom: 1rem !important;
}

.slide--wrapper {
  justify-items: center;
  display: grid;
  gap: 40px;
  padding: 0 4rem;
}

.slide--text {
  gap: 20px;
  display: grid;
  justify-items: center;
}

.main-btn {
  width: fit-content;
}