@media screen and (max-width: 599px) {  
    .nav--drawer--text {
        content: 'Repairs4Ranges';
        font-size: 0.7em;
    }
  }

@media screen and (min-width: 600px) and (max-width: 735px) {  
    .top-nav-header-text {
        // display: none;
        content: 'Repairs4Ranges';
        font-size: 0.7em;
    }
    .top-nav-left-content > svg {
padding: 10px;
    }
  }

  @media screen and (min-width: 736px) and (max-width: 814px) {  
    .top-nav-header-text {
        // display: none;
        font-size: 0.7em;
    }
    .top-nav-left-content > svg {
padding: 10px;
    }
  }

  @media screen and (min-width: 815px) and (max-width: 938px) {  
    .top-nav-header-text {
        // display: none;
        font-size: 1em;
    }
  }
